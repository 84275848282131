.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.left-5 {
  left: 1.25rem;
}

.left-\[43px\] {
  left: 43px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-3 {
  top: .75rem;
}

.top-\[35px\] {
  top: 35px;
}

.top-px {
  top: 1px;
}

.z-50 {
  z-index: 50;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-11 {
  height: 2.75rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[353px\] {
  height: 353px;
}

.h-\[387px\] {
  height: 387px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[536px\] {
  height: 536px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.w-11 {
  width: 2.75rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[33px\] {
  width: 33px;
}

.w-\[400px\] {
  width: 400px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-\[646px\] {
  max-width: 646px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.grow {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[24px_24px_0px_0px\] {
  border-radius: 24px 24px 0 0;
}

.rounded-\[34px\] {
  border-radius: 34px;
}

.border {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#FF4444\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 68 68 / var(--tw-border-opacity, 1));
}

.border-\[\#dfdfdf\] {
  --tw-border-opacity: 1;
  border-color: rgb(223 223 223 / var(--tw-border-opacity, 1));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}

.bg-\[\#001011\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 16 17 / var(--tw-bg-opacity, 1));
}

.bg-\[\#4482ef\] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 130 239 / var(--tw-bg-opacity, 1));
}

.bg-\[\#eaeced\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 236 237 / var(--tw-bg-opacity, 1));
}

.bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[200px\] {
  font-size: 200px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[27px\] {
  line-height: 27px;
}

.leading-\[44\.8px\] {
  line-height: 44.8px;
}

.leading-\[536\.0px\] {
  line-height: 536px;
}

.leading-\[normal\] {
  line-height: normal;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[0\.10px\] {
  letter-spacing: .1px;
}

.tracking-\[0\.20px\] {
  letter-spacing: .2px;
}

.tracking-\[0\.48px\] {
  letter-spacing: .48px;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.text-\[\#001011\] {
  --tw-text-opacity: 1;
  color: rgb(0 16 17 / var(--tw-text-opacity, 1));
}

.text-\[\#313e48\] {
  --tw-text-opacity: 1;
  color: rgb(49 62 72 / var(--tw-text-opacity, 1));
}

.text-\[\#4a4a4a\] {
  --tw-text-opacity: 1;
  color: rgb(74 74 74 / var(--tw-text-opacity, 1));
}

.text-\[\#808788\] {
  --tw-text-opacity: 1;
  color: rgb(128 135 136 / var(--tw-text-opacity, 1));
}

.text-\[\#8a8a8a\] {
  --tw-text-opacity: 1;
  color: rgb(138 138 138 / var(--tw-text-opacity, 1));
}

.text-\[\#FF4444\] {
  --tw-text-opacity: 1;
  color: rgb(255 68 68 / var(--tw-text-opacity, 1));
}

.text-\[\#ffffff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-25 {
  opacity: .25;
}

.mix-blend-darken {
  mix-blend-mode: darken;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.\[font-family\:\'Figma_Hand-Bold\'\,Helvetica\] {
  font-family: Figma Hand-Bold, Helvetica;
}

.\[font-family\:\'Figtree\'\,Helvetica\] {
  font-family: Figtree, Helvetica;
}

.\[font-family\:\'Manrope\'\,Helvetica\] {
  font-family: Manrope, Helvetica;
}

:root {
  --white: #fff;
}

.hover\:bg-\[\#152425\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 36 37 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (width >= 768px) {
  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:w-\[400px\] {
    width: 400px;
  }

  .md\:w-\[500px\] {
    width: 500px;
  }

  .md\:w-\[850px\] {
    width: 850px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-14 {
    gap: 3.5rem;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-\[72px\] {
    gap: 72px;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-\[300px\] {
    padding-left: 300px;
    padding-right: 300px;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-\[32px\] {
    font-size: 32px;
  }

  .md\:text-\[357\.3px\] {
    font-size: 357.3px;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:leading-\[44\.8px\] {
    line-height: 44.8px;
  }

  .md\:leading-\[57\.6px\] {
    line-height: 57.6px;
  }

  .md\:leading-\[72\.0px\], .md\:leading-\[72px\] {
    line-height: 72px;
  }
}

@media (width >= 1024px) {
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
/*# sourceMappingURL=index.9662fcaa.css.map */
